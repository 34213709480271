<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap">
      <div class="px-4 w-full">
        <BigTitle title="Settings" class="mb-11 pt-40"/>
        <ContactForTeamPlan />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForTeamPlan from '@/components/settings/ContactForTeamPlan'
import BigTitle from '@/components/BigTitle'

export default {
  name: 'Settings-Billing-Plans-Contact',
  components: { 
    ContactForTeamPlan,
    BigTitle
  },
}
</script>

<style>

</style>