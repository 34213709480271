<template>
  <div>
    <template v-if="isSent">
      <SettingsTitle title="Thank you for your application"/>
      <p class="font-grey-darker-500 text-2xl mt-4">
        We’ll get back to you within 2 business days.
      </p>
    </template>
    <template v-else>
      <SettingsTitle title="Request Free Access as a Journalist or Academic Researcher"/>
      <form @submit.prevent="sendData">
        <AccountLineInput 
          title="Organization name" 
          valueKey="organizationName"
          placeholder="Organization name"
          @accountData="setData" 
          :required="true"
        />
        <div class="w-full flex border-b border-dashed border-grey-darker-200">
          <div class="w-2/6 border-r border-grey-darker-200 py-5">
            <span class="font-sansDemi base-font-gray-200 text-xl leading-none">I qualify as a(n)</span>
          </div>
          <div class="w-4/6">
            <List
              :items="amountOfTeamMembers" 
              :activeItem="membersAmount"
              @setItem="selectMembersAmount"
            />
          </div>
        </div>
        <div class="w-full flex">
          <div class="w-2/6 py-5">
          </div>
          <div class="w-4/6 pl-8">
            <button
              class="font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mt-4"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
  import SettingsTitle from '@/components/settings/SettingsTitle'
  import AccountLineInput from '@/components/settings/AccountLineInput'
  import List from '@/components/settings/List'
  import { warningToastConfig, amountOfTeamMembers } from '@/helpers/constants'

  export default {
    components: { 
      SettingsTitle,
      AccountLineInput,
      List 
    },
    data(){
      return {
        organizationName: '',
        membersAmount: '',
        isSent: false,
        amountOfTeamMembers: amountOfTeamMembers
      }
    },
    methods: {
      setData(data){
        const keyName = Object.keys(data)[0]
        this[keyName] = data[keyName]
      },
      selectMembersAmount(data){
        this.membersAmount = data
      },
      async sendData(){
        try {
          await this.$axios.post('https://formspree.io/f/mayarjna', {
            organizationName: this.organizationName,
            membersAmount: this.membersAmount
          })
          this.isSent = true
        } catch (error) {
          this.$toasted.show(error, warningToastConfig)
        }
      }
    },
    mounted(){
      this.membersAmount = this.amountOfTeamMembers[0].value
    }
  }
</script>

<style lang="scss" scoped>

</style>